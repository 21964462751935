@font-face {
  font-family: "Neue Montreal";
  src:
    url("/assets/fonts/PPNeueMontreal-Regular.woff2") format("woff2"),
    url("/assets/fonts/PPNeueMontreal-Regular.woff") format("woff"),
    url("/assets/fonts/PPNeueMontreal-Regular.ttf") format("ttf");
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: "Neue Montreal";
  src:
    url("/assets/fonts/PPNeueMontreal-Medium.woff2") format("woff2"),
    url("/assets/fonts/PPNeueMontreal-Medium.woff") format("woff"),
    url("/assets/fonts/PPNeueMontreal-Medium.ttf") format("ttf");
  font-weight: 500;
  font-display: block;
}

@font-face {
  font-family: "Neue Montreal";
  src:
    url("/assets/fonts/PPNeueMontreal-SemiBold.woff2") format("woff2"),
    url("/assets/fonts/PPNeueMontreal-SemiBold.woff") format("woff"),
    url("/assets/fonts/PPNeueMontreal-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-display: block;
}

@font-face {
  font-family: "Neue Montreal";
  src:
    url("/assets/fonts/PPNeueMontreal-Bold.woff2") format("woff2"),
    url("/assets/fonts/PPNeueMontreal-Bold.woff") format("woff"),
    url("/assets/fonts/PPNeueMontreal-Bold.ttf") format("ttf");
  font-weight: 700;
  font-display: block;
}

@font-face {
  font-family: "Editorial New";
  src:
    url("/assets/fonts/PPEditorialNew-Light.woff2") format("woff2"),
    url("/assets/fonts/PPEditorialNew-Light.woff") format("woff"),
    url("/assets/fonts/PPEditorialNew-Light.ttf") format("ttf");
  font-weight: 300;
  font-display: block;
}

@font-face {
  font-family: "Editorial New";
  src:
    url("/assets/fonts/PPEditorialNew-Ultralight.woff2") format("woff2"),
    url("/assets/fonts/PPEditorialNew-Ultralight.woff") format("woff"),
    url("/assets/fonts/PPEditorialNew-Ultralight.ttf") format("ttf");
  font-weight: 200;
  font-display: block;
}

@font-face {
  font-family: "Editorial New";
  src:
    url("/assets/fonts/PPEditorialNew-Thin.woff2") format("woff2"),
    url("/assets/fonts/PPEditorialNew-Thin.woff") format("woff"),
    url("/assets/fonts/PPEditorialNew-Thin.ttf") format("ttf");
  font-weight: 100;
  font-display: block;
}
